<template>
  <a-row :gutter='25'>
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col
      :xxl='24'
      :lg='24'
      :md='24'
      :xs='24'
      v-if='showLoader'>
      <sdCards>
        <a-spin size='small' style='position:relative; left: 45%;margin-top: 2rem;' />
      </sdCards>
    </a-col>
    <a-col
      :xxl='24'
      :lg='24'
      :md='24'
      :xs='24'
      v-if='!showLoader'>


      <a-row :gutter='25' class='ml-2'>
        <span class='event--title'>{{ event.title }}</span><br>
      </a-row>
      <a-row :gutter='25' class='ml-2 mt-2'>
        <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
          <span class='field-title'>Fecha de inicio del evento</span><br>
          <span class='field-text-value'>
            {{ event.event_start_date ? `${getStringDate(event.event_start_date)}, ${removeSeconds(event.event_start_hour)}hrs.` : 'Sin definir'}}
          </span>
        </a-col>
        <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
          <span class='field-title'>Fecha de finalización del evento</span><br>
          <span class='field-text-value'>
            {{ event.event_end_date ? `${getStringDate(event.event_end_date)}, ${removeSeconds(event.event_end_hour)}hrs.` : 'Sin definir'}}
          </span>
        </a-col>
      </a-row>


      <tenplate v-if='!collapsed'>
        <a-row :gutter='25' class='ml-2 mt-2'>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>Fecha de inicio de registro</span><br>
            <span class='field-text-value'>{{ getStringDate(event.start_register_date) || '-' }}</span>
          </a-col>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>Fecha de finalización del registro</span><br>
            <span class='field-text-value'>{{ getStringDate(event.end_register_date) || '-' }}</span>
          </a-col>
        </a-row>
        <a-row :gutter='25' class='ml-2 mt-2'>
          <a-col class='card-fields' :xxl='12' :lg='12' :md='24' :xs='24'>
            <span class='field-title'>Sedes</span>
            <a-col v-for='(sede, index) in getSedesData(event)' :key='sede'
                   :xxl='24' :lg='24' :md='24' :xs='24' class='card-fields'>
              <span class='title-normal'>#{{ index + 1 }}</span><br>
              <div v-for='element in sede' :key='element'>
                <div v-if='element.value != null'>
                  <span class=''>{{ element.label }}</span><br>
                  <span class='field-text-value'>{{ element.value }}</span>
                </div>
              </div>
            </a-col>
          </a-col>
          <a-col class='card-fields' :xxl='12' :lg='12' :md='24' :xs='24'>
            <span class='field-title'>Centros de trabajo participantes</span>
            <div v-if="workCentersNames.length > 0">
              <span v-for="workCenter in workCentersNames" :key="workCenter.id" class='field-text-value'>
                <a-row >
                {{ workCenter.nombre.charAt(0) + workCenter.nombre.slice(1).toLowerCase() }}
                </a-row>

              </span>
            </div>
            <a-row v-else>
              <span  class='field-text-value'> - </span>
            </a-row>

          </a-col>
        </a-row>
        <a-row :gutter='25' class='ml-2 mt-2'>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>¿A quién va dirigido el evento?</span><br>
            <span class='field-text-value'>{{
                `${event.admin_can_register == 0 ? "" : "Personal en centro de trabajo administrativo"}
                 ${event.edu_can_register == 0 ? "" : "Estructura educativa"}
                ${event.student_can_register == 0 ? "" : "Estudiantes"} ${event.citizen_can_register == 0 ? "" : "Ciudadanos"} `
              }}</span>
          </a-col>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>¿Sólo el Director de CT puede registrar? </span><br>
            <span class='field-text-value'>{{ event.director_register ? "Sí" : "No" }}</span>
          </a-col>
        </a-row>
        <a-row :gutter='25' class='ml-2 mt-2'>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>¿Sólo el Administrador de Área puede registrar?</span><br>
            <span class='field-text-value'>{{ event.only_admin_register ? "Sí" : "No" }}</span>
          </a-col>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>Cantidad máxima de participantes</span><br>
            <span class='field-text-value'>{{ event.maximum_assistance || 'Sin límite' }}</span>
          </a-col>
        </a-row>
        <a-row :gutter='25' class='ml-2 mt-2'>
          <a-col :xxl='12' :lg='12' :md='12' :xs='24' class='card-fields'>
            <span class='field-title'>¿El evento tendrá actividades?</span><br>
            <span class='field-text-value'>{{ event.activities ? 'Si' : 'No' }}</span>
          </a-col>
        </a-row>
        <br>
        <a-row :gutter='25' v-if='dynamicForm != null && event.activities == 1'>
          <a-col
            :xxl='24'
            :lg='24'
            :md='24'
            :xs='24'>
            <button class='button-normal' @click='showDynamicForm = !showDynamicForm'>
              <span class='title-normal' v-if='!showDynamicForm'>Ver formulario dinámico</span>
              <span class='title-normal' v-else>Ocultar formulario dinámico</span>
            </button>
          </a-col>
        </a-row>
      </tenplate>
      <br>
      <dynamic-form-component
        v-if="showDynamicForm && dynamicForm != null && !collapsed"
        :dynamic_form="dynamicForm" />
    </a-col>
  </a-row>
  <div class='w-100 mt-2' style='background-color: #CCCCCC; height: 1px' />
  <a-row :gutter='25' class='ml-2 mt-2 justify-content-center'>
    <sdFeatherIcons
      class='btn-collapse' :type="collapsed ? 'chevron-down' : 'chevron-up'"
      size='21'
      @click='collapsed=!collapsed'/>
  </a-row>

</template>

<script>
import { defineAsyncComponent } from 'vue';
import { formatDate, removeSeconds } from '@/utility/utility';

const DynamicFormComponent = defineAsyncComponent(() => import('./dynamicForm.vue'));

const SubEvent = {
  name: 'ValidationSubEventsDetailsComponent',
  components: {
    DynamicFormComponent
  },

  props: {
    event: null,
    dynamicForm: null,
    workCenters: null
  },
  mounted() {
  let worksCentersNames = []
    if(this.event.work_center_ids?.length > 0){
      if(this.workCenters.length){
        JSON.parse(this.event.work_center_ids).forEach(workCenter => {
          worksCentersNames.push(this.workCenters.find(item => item.id == workCenter))
        });
      }
      this.workCentersNames = worksCentersNames
    }
  },
  data() {
    return {
      showLoader: false,
      base_url: process.env.VUE_APP_BASE_URL,
      //dynamicForm: this.dynamicForm,
      collapsed: true,
      showDynamicForm: false,
      workCentersNames: []

    };
  },
  methods: {
    removeSeconds(hour){return removeSeconds(hour);},
    getStringDate(date){return formatDate(date);},
    getSedesData(event) {
      let fields = [];
      event.headquarters.forEach(element => {
        let sede = '';
        let type = '';
        if (element.type == 'face-to-face') {
          type = 'Presencial';
          sede = `${element.name}, ${element.street}, ${element.suburb ? element.suburb+',' : element.other_suburb ? element.other_suburb+',' :''} ${element.municipality ? element.municipality+',' : ''} ${element.postal_code ? element.postal_code : ''}`

        } else if (element.type == 'virtual') {
          type = 'Virtual';
          sede = `${element.virtual_platform}, ${element.link}`;
        } else {
          type = 'Ambas (vitual y presencial)';
          sede = `${element.virtual_platform}, ${element.link}, ${element.name}, ${element.street}, ${element.suburb ? element.suburb+',' : element.other_suburb ? element.other_suburb+',' :''} ${element.municipality ? element.municipality+',' : ''} ${element.postal_code ? element.postal_code : ''}`
        }
        fields.push([
          {
            label: type,
            value: sede,
          },
        ]);
      });

      return fields;
    },
  },

};
export default SubEvent;
</script>
<style scoped>
.button-normal {
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 8px 15px 8px 15px;
  cursor: pointer;
}

.card-title {
  padding: 1rem;
  border-left: 0.5rem solid #3fa7e1;
  background: #f8f8f8;
  border-top: 2px solid #e8e5e5;
  font-weight: bold !important;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

.card-fields {
  padding: 0 !important;
}

.field-title {
  font-weight: 600;
  font-family: "Avenir Heavy";
  font-size: 14px;
}

.field-text-value {
  color: #7B868C;
  font-family: "Avenir Roman";
  font-size: 14px;
}

.text-file {
  color: #E8394D;
}

.event--title{
  font-family: "Nutmeg Bold";
  font-size: 18px;
  color: #404040 ;
}
.btn-collapse{
  background-color: rgba(63, 167, 225, 0.15);
  color: #3FA7E1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-collapse:hover{
  cursor: pointer;
}
</style>
